import * as Sentry from "@sentry/react";

const CustomFallback = () => (
    <div>
        <p>Unexpected error</p>
    </div>
);

const SentryErrorBoundary = ({ children }: { children: React.ReactNode }) => {
    return (
        <Sentry.ErrorBoundary fallback={<CustomFallback />}>
            {children}
        </Sentry.ErrorBoundary>
    );
};

export default SentryErrorBoundary