import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App.tsx'
import './index.css'
import { makeStore } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from "@sentry/react";
import SentryErrorBoundary from './components/SentryErrorBoundary.tsx'

Sentry.init({
  dsn: "https://0f97b596e38be2d0b1d5f694a5e8c500@o4507550808080384.ingest.us.sentry.io/4507572826996736",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Sentry.setTag("app", "Admin")

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <div id="modal" />
    <div id="confirmDialog" />
    <div id="loading" />
    <Provider store={makeStore().store}>
      <PersistGate loading={null} persistor={makeStore().persistor}>
        <ToastContainer />
        <SentryErrorBoundary>
          <App />
        </SentryErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)
