import * as Sentry from "@sentry/react"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Breadcrumb from "../../../components/Breadcrumb/breadcrumb"
import QuizList from "../../../components/Quiz/List"
import { SENTRY_CONTEXT } from "../../../constants/sentry"
import useHandleEvent from "../../../hooks/useHandleEvent"
import { fetchCanvasCourses, fetchCanvasNewQuizzes, fetchCanvasQuizzes } from "../../../redux/modules/canvas/actions"
import { useAppDispatch } from "../../../redux/store"
import { isCourseFinished } from "../../../utils/course"

const DashboardCoursesIdPage = () => {

    const { courseId } = useParams()
    const [courseName, setCourseName] = useState("")
    const dispatch = useAppDispatch()
    const { handleEvent } = useHandleEvent()

    useEffect(() => {

        Sentry.setContext(SENTRY_CONTEXT.COURSE, {
            uid: courseId,
        });

        const fetchData = () => handleEvent(async () => {

            const courses = await dispatch(fetchCanvasCourses())

            const course = courses.find(e => e.id === Number(courseId))

            if (!course) throw "course does not exists"

            setCourseName(course.name)

            const promises: Promise<any>[] = []

            promises.push(dispatch(fetchCanvasQuizzes(String(courseId))))

            if (!isCourseFinished(course)) {
                dispatch(fetchCanvasNewQuizzes(String(courseId)))
            }

            await Promise.all(promises)
        })

        fetchData()

    }, [])

    return (
        <>
            <Breadcrumb courseName={courseName} />
            <QuizList courseId={String(courseId)} courseName={courseName} />
        </>
    )
}

export default DashboardCoursesIdPage
