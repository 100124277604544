import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { GameFormI } from "../components/Game/Form/props";
import { FS_FIELDS } from "../constants/firebase";
import { db } from "../firebase";
import { AnswerI, QuizQuestionI } from "../models/canvas/question";
import { uploadFile } from "./storage";
import { NewQuizItem } from "../models/canvas/newItem";
import { QuizTypeI } from "../models/canvas/quiz";

export const updateGame = async (gameId: string, data: any) => {
    return await updateDoc(doc(db, FS_FIELDS.GAMES, gameId), data);
}

export const createGame = async (data: any) => {
    return await addDoc(collection(db, FS_FIELDS.GAMES), data)
}

interface CreateGameFromFormProps {
    quizId: number,
    userId: string,
    quizQuestions: QuizQuestionI[] | NewQuizItem[],
    coverImage?: File
    formData: GameFormI,
    courseId: string
    type: QuizTypeI
}

export const createGameFromForm = async ({
    quizId, userId, quizQuestions,
    coverImage, formData, courseId,
    type,
}: CreateGameFromFormProps) => {

    const questionsSortered = quizQuestions.sort(() => Math.random() - 0.5)
        .slice(0, formData.numberOfQuestions)
        .map((question: any) => {

            if ('answers' in question) {
                const answers = question.answers as AnswerI[];
                const shuffledAnswers = [...answers]
                    .sort(() => Math.random() - 0.5);

                return {
                    ...question,
                    answers: shuffledAnswers
                };
            }

            if (type === "new-quiz" && question.entry?.interaction_data?.choices) {

                const shuffledChoices = [...question.entry.interaction_data.choices]
                    .sort(() => Math.random() - 0.5);

                return {
                    ...question,
                    entry: {
                        ...question.entry,
                        interaction_data: {
                            ...question.entry.interaction_data,
                            choices: shuffledChoices
                        }
                    }
                };
            }

            return question

        })

    let coverImageFirebase = ""

    if (coverImage) {
        const { url } = await uploadFile(coverImage, `${FS_FIELDS.GAMES}/${formData.name}-${new Date().getTime()}`)
        coverImageFirebase = url
    }

    return await createGame({
        quizId,
        questions: questionsSortered,
        owner: userId,
        courseId,
        name: formData.name,
        coverImage: coverImageFirebase,
        gameType: formData.gameType,
        prize: formData.prize,
        durationBetweenAnswers: Number(formData.durationBetweenAnswers),
        type,
    })

}